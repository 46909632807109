.footerComponent__wrapper .footerCopyright__top b,
.footerComponent__wrapper .footerLegalNotice__small b,
.footerComponent__wrapper .footerLinks .linksList__btn b,
.footerComponent__wrapper .footerLinks .linksList__li b,
.footerComponent__wrapper .footerLinks .linksList__noBtn b,
.footerComponent__wrapper .legalLinks__link b,
.footerComponent__wrapper section.footerNewsletter .newsLetter__input b,
body .footerComponent__title b {
  font-weight: 600;
}
.footerComponent__wrapper .footerCopyright__top i,
.footerComponent__wrapper .footerLegalNotice__small i,
.footerComponent__wrapper .footerLinks .linksList__btn i,
.footerComponent__wrapper .footerLinks .linksList__li i,
.footerComponent__wrapper .footerLinks .linksList__noBtn i,
.footerComponent__wrapper .legalLinks__link i,
.footerComponent__wrapper section.footerNewsletter .newsLetter__input i,
body .footerComponent__title i {
  font-style: italic;
}
[data-focus-tabbed] {
  outline: 0;
  position: relative;
}
[data-focus-tabbed]::after {
  background-color: transparent;
  border: 2px solid #3470df;
  border-radius: inherit;
  bottom: -1px;
  content: "";
  display: none;
  left: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: -1px;
  z-index: 11;
}
[data-focus-tabbed]:focus-visible::after {
  content: "";
  display: block;
}
.sr-only {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
body .footerComponent {
  background: #f4f4f5;
  color: #252339;
  font-size: initial;
  margin-top: 56px;
  overflow: auto;
}
@media screen and (min-width: 768px) {
  body .footerComponent {
    margin-top: 80px;
  }
}
@media screen and (min-width: 1024px) {
  body .footerComponent {
    margin-top: 96px;
  }
}
body .footerComponent__wrapper {
  -ms-flex-align: baseline;
  align-items: baseline;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 0 auto;
  padding: 16px;
  max-width: 100%;
}
@media screen and (min-width: 768px) {
  body .footerComponent__wrapper {
    padding: 16px 32px 32px;
  }
}
@media screen and (min-width: 1024px) {
  body .footerComponent__wrapper {
    max-width: 1436px;
    padding: 16px 64px 64px;
  }
}
body .footerComponent__hr {
  border: 0;
  border-color: #fff;
  border-top: 1px solid #dadadd;
  clear: both;
  margin: 32px 0;
  opacity: 0.15;
  width: 100%;
}
@media screen and (min-width: 1024px) {
  body .footerComponent__hr {
    max-width: 1308px;
  }
}
@media screen and (min-width: 768px) {
  body .footerComponent__hr--mobile {
    display: none;
  }
}
body .footerComponent__hr--main {
  opacity: 0.6;
}
body .footerComponent__hr--invisible {
  border: 0;
  margin: 24px 0;
}
@media screen and (max-width: 767px) {
  body .footerComponent__hr--invisible {
    margin: 16px 0;
  }
}
body .footerComponent__title {
  font-family: Roboto, Arial, sans-serif;
  font-size: 24px;
  line-height: 32px;
  font-weight: initial;
  text-align: left;
  margin: 0;
}
body .footerComponent a,
body .footerComponent button {
  color: #050033;
  cursor: pointer;
  font-family: sans-serif;
}
body .footerComponent a,
body .footerComponent button,
body .footerComponent input[type="text"] {
  text-decoration: none;
}
body .footerComponent a:focus,
body .footerComponent button:focus,
body .footerComponent input[type="text"]:focus {
  box-shadow: 0 0 0 1px #fff, 0 0 0 3px #3470df;
  outline: 0;
}
body .footerComponent a.inverse:focus,
body .footerComponent button.inverse:focus,
body .footerComponent input[type="text"].inverse:focus {
  box-shadow: 0 0 0 1px #050033, 0 0 0 3px #fff;
}
body .footerComponent input[type="radio"] {
  background: #fff;
}
body .footerComponent ul {
  list-style: none;
  padding: 0;
}
body .footerComponent .footerSection {
  margin: 8px 0;
  width: 100%;
}
body .footerComponent .roundButton {
  background: #1e1852;
  border: 1px solid #1e1852;
  border-radius: 30px;
  color: #1e1852;
  display: block;
  padding: 13px 32px;
  position: relative;
  text-align: center;
  transition: background 0.2s, border 0.2s;
}
body .footerComponent .roundButton:hover {
  background: #38326c;
  border-color: #38326c;
}
body .footerComponent .roundButton:active {
  background: #544e85;
  border-color: #544e85;
}
body .footerComponent .roundButton:focus {
  box-shadow: 0 0 0 1px #fff, 0 0 0 3px #3470df;
  outline: 0;
}
body .footerComponent .roundButton--secondaryInv {
  background: 0;
  border-color: #1e1852;
}
body .footerComponent .roundButton--secondaryInv:hover {
  background: #1e1852;
  border-color: #1e1852;
  color: #f4f4f5;
}
body .footerComponent .roundButton--secondaryInv:active {
  background: #1e1852;
  border-color: #1e1852;
  color: #1e1852;
}
body .footerComponent .roundButton--secondaryInv:focus {
  box-shadow: 0 0 0 1px #050033, 0 0 0 3px #fff;
}
.footerComponent__wrapper .footerCopyright {
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
}
.footerComponent__wrapper .footerCopyright__top {
  font-family: Roboto, Arial, sans-serif;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
}
@media screen and (min-width: 768px) {
  .footerComponent__wrapper .footerCopyright__top {
    text-align: center;
  }
}
.footerComponent__wrapper .footerCopyright__elmt {
  display: block;
}
.footerComponent__wrapper .footerCopyright__elmt:last-child {
  margin-top: 6px;
}
@media screen and (min-width: 768px) {
  .footerComponent__wrapper .footerCopyright__elmt {
    display: inline-block;
    margin: 0 20px;
  }
}
.footerComponent__wrapper .footerLinks,
.footerComponent__wrapper .legalLinks {
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
}
.footerComponent__wrapper .footerLinks .linksList {
  margin: 12px 0;
}
@media screen and (min-width: 768px) {
  .footerComponent__wrapper .footerLinks .linksList {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
}
@media screen and (min-width: 1280px) {
  .footerComponent__wrapper .footerLinks .linksList {
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
}
.footerComponent__wrapper .footerLinks .linksList__list {
  border-top: 1px solid rgb(229 229 229);
  padding: 12px 0;
}
.footerComponent__wrapper .footerLinks .linksList__list:first-child {
  border: 0;
  padding-top: 0;
}
.footerComponent__wrapper .footerLinks .linksList__list:last-child {
  padding-bottom: 0;
}
@media screen and (min-width: 768px) {
  .footerComponent__wrapper .footerLinks .linksList__list {
    border: 0;
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
    margin: 24px 0;
    padding: 0;
  }
}
@media screen and (min-width: 1024px) {
  .footerComponent__wrapper .footerLinks .linksList__list {
    -ms-flex-preferred-size: 25%;
    flex-basis: 25%;
    margin: 0;
  }
}
@media screen and (min-width: 1280px) {
  .footerComponent__wrapper .footerLinks .linksList__list {
    -ms-flex-preferred-size: 20%;
    flex-basis: 20%;
  }
}
.footerComponent__wrapper .footerLinks .linksList__btn,
.footerComponent__wrapper .footerLinks .linksList__noBtn {
  background: 0;
  border: 0;
  font-family: Roboto, Arial, sans-serif;
  font-size: 18px;
  line-height: 24px;
  font-weight: 500;
}
.footerComponent__wrapper .footerLinks .linksList__btn {
  display: block;
  color: inherit;
  padding: 0;
  position: relative;
  text-align: left;
  width: 100%;
}
.footerComponent__wrapper .footerLinks .linksList__btn::after,
.footerComponent__wrapper .footerLinks .linksList__btn::before {
  background: #050033;
  content: "";
  display: block;
  height: 2px;
  position: absolute;
  top: 50%;
  transition: transform 0.5s;
  width: 8px;
}
.footerComponent__wrapper .footerLinks .linksList__btn::before {
  right: 5px;
  transform: rotate(45deg);
}
.footerComponent__wrapper .footerLinks .linksList__btn::after {
  right: 0;
  transform: rotate(-45deg);
}
@media screen and (min-width: 768px) {
  .footerComponent__wrapper .footerLinks .linksList__btn {
    display: none;
  }
}
.footerComponent__wrapper .footerLinks .linksList__noBtn,
.footerComponent__wrapper section.helps.footerLinks--help .linksList__btn {
  display: none;
}
@media screen and (min-width: 768px) {
  .footerComponent__wrapper .footerLinks .linksList__noBtn {
    display: inline;
  }
}
.footerComponent__wrapper .footerLinks .linksList__ul {
  display: none;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease;
}
@media screen and (min-width: 768px) {
  .footerComponent__wrapper .footerLinks .linksList__ul {
    display: block;
    max-height: none;
    overflow: visible;
  }
}
.footerComponent__wrapper .footerLinks .linksList__li {
  font-family: Roboto, Arial, sans-serif;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  margin-top: 12px;
}
.footerComponent__wrapper section.helps {
  -ms-flex-preferred-size: calc(25% - 32px);
  flex-basis: calc(25% - 32px);
  margin: 0;
  padding: 32px 0 32px 32px;
}
@media screen and (max-width: 767px) {
  .footerComponent__wrapper section.helps {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .footerComponent__wrapper section.helps {
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1279px) {
  .footerComponent__wrapper section.helps {
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
  }
}
@media screen and (max-width: 1279px) {
  .footerComponent__wrapper section.helps {
    padding-left: 0;
  }
}
@media screen and (min-width: 768px) {
  .footerComponent__wrapper section.helps {
    -ms-flex-line-pack: start;
    align-content: flex-start;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
}
.footerComponent__wrapper section.helps.footerLinks--help .linksList__ul {
  margin-top: 30px;
}
.footerComponent__wrapper section.helps.footerLinks--help .linksList__li {
  margin-top: 12px;
}
@media screen and (min-width: 768px) {
  .footerComponent__wrapper .legalLinks__ul {
    text-align: center;
  }
}
.footerComponent__wrapper .legalLinks__li {
  margin-top: 6px;
}
@media screen and (min-width: 768px) {
  .footerComponent__wrapper .legalLinks__li {
    display: inline-block;
    margin: 0 19px;
  }
  .footerComponent__wrapper .legalLinks__li:first-child {
    margin-left: 0;
  }
  .footerComponent__wrapper .legalLinks__li:last-child {
    margin-right: 0;
  }
}
.footerComponent__wrapper .legalLinks__link {
  font-family: Roboto, Arial, sans-serif;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  display: block;
}
.footerComponent__wrapper .legalLinks__abbr[title] {
  text-decoration: none;
}
.footerComponent__wrapper .footerLegalLogos {
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
}
.footerComponent__wrapper .footerLegalLogos__logos {
  text-align: center;
}
.footerComponent__wrapper .footerLegalLogos__logo {
  display: inline-block;
  vertical-align: middle;
}
.footerComponent__wrapper .footerLegalLogos__logo--fevad {
  margin-right: 17px;
}
.footerComponent__wrapper .footerLegalLogos__logo--secure {
  margin-left: 17px;
}
.footerComponent__wrapper .footerLegalNotice {
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
}
.footerComponent__wrapper .footerLegalNotice__small {
  font-family: Roboto, Arial, sans-serif;
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  margin-top: 12px;
  text-align: center;
}
.footerComponent__wrapper .logo {
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
  color: #fff;
  overflow: auto;
  width: 100%;
}
.footerComponent__wrapper .logo .hidden {
  display: none;
}
.footerComponent__wrapper .logo__wrapper {
  margin: 0 auto;
  max-width: 1436px;
}
.footerComponent__wrapper .logo__hr {
  border: 0;
  border-top: 1px solid #fff;
  clear: both;
  margin: 40px 0 0;
  opacity: 0.6;
}
.footerComponent__wrapper .logo__hr:nth-child(1) {
  opacity: 0.6;
}
.footerComponent__wrapper .logo__svg {
  background-color: #050033;
  display: block;
  fill: #fff;
  height: 60px;
  margin: -30px auto 0;
  padding: 0 32px;
  position: relative;
  width: 264px;
}
.footerComponent__wrapper .loyalty {
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
}
.footerComponent__wrapper .loyalty__button {
  margin: auto;
  width: fit-content;
}
.footerComponent__wrapper section.footerNewsletter {
  -ms-flex-preferred-size: 50%;
  flex-basis: 50%;
  margin: 0;
}
@media screen and (max-width: 1279px) {
  .footerComponent__wrapper section.footerNewsletter {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
  }
  .footerComponent__wrapper section.footerNewsletter .newsLetter {
    border-bottom: 1px solid rgba(255, 255, 255, 0.15);
    margin-bottom: 32px;
    padding-bottom: 32px;
  }
}
@media screen and (min-width: 1280px) {
  .footerComponent__wrapper section.footerNewsletter .newsLetter {
    border-right: 1px solid rgba(255, 255, 255, 0.15);
    margin-bottom: 32px;
    padding-right: 32px;
  }
}
.footerComponent__wrapper section.footerNewsletter .newsLetter__text {
  margin-top: 10px;
  font-family: sans-serif;
}
@media screen and (min-width: 1024px) {
  .footerComponent__wrapper section.footerNewsletter .newsLetter__text {
    margin-top: 30px;
  }
}
.footerComponent__wrapper section.footerNewsletter .newsLetter__form {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-top: 40px;
}
@media screen and (min-width: 768px) {
  .footerComponent__wrapper section.footerNewsletter .newsLetter__form {
    margin-top: 46px;
  }
}
@media screen and (min-width: 1024px) {
  .footerComponent__wrapper section.footerNewsletter .newsLetter__form {
    margin-top: 20px;
  }
}
.footerComponent__wrapper section.footerNewsletter .newsLetter__input {
  font-family: Roboto, Arial, sans-serif;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  border: 0;
  border-radius: 6px;
  padding: 16px;
  width: 100%;
}
@media screen and (min-width: 768px) {
  .footerComponent__wrapper section.footerNewsletter .newsLetter__input {
    width: calc((100% + 24px) / 8 * 4 - 24px);
    margin-right: 24px;
  }
}
.footerComponent__wrapper section.footerNewsletter .newsLetter__button {
  margin: 30px auto 0;
  font-size: 100%;
}
.newsLetter__button:hover {
  background-color: #1e1852;
  color: white;
}
@media screen and (min-width: 768px) {
  .footerComponent__wrapper section.footerNewsletter .newsLetter__button {
    margin: 0;
  }
  .footerComponent__wrapper section.footerNewsletter .footerComponent__hr {
    display: none;
  }
}

.footerComponent__wrapper section.footerNewsletter .newsLetter__radio {
  display: flex;
  padding-bottom: 20px;
  width: 100%;
}

.footerComponent__wrapper section.footerNewsletter .newsLetter__radio .label {
  align-items: baseline;
  display: flex;
  padding-right: 20px;
}

.footerComponent__wrapper section.footerNewsletter .newsLetter__radio .label__text {
  flex: 1;
  padding-left: 0.5em;
}

.footerComponent__wrapper .footerSharing {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
}
@media screen and (max-width: 767px) {
  .footerComponent__wrapper .footerSharing {
    display: block;
  }
}
@media screen and (max-width: 1023px) {
  .footerComponent__wrapper .footerSharing__ul {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;
  }
}
.footerComponent__wrapper .footerSharing__li {
  -ms-flex-preferred-size: 16.66666667%;
  flex-basis: 16.66666667%;
}
@media screen and (min-width: 768px) {
  .footerComponent__wrapper .footerSharing__li {
    display: inline-block;
    margin: 0 20px;
  }
}
.footerComponent__wrapper .footerSharing__li--weibo {
  display: none;
}
.footerComponent__wrapper .footerSharing__link {
  display: block;
  line-height: 0;
}
.footerComponent__wrapper .footerSharing__svg {
  fill: #1e1852;
  height: 28px;
  width: 28px;
}
.footerComponent__wrapper .footerSharing__tripadvisorDiv {
  margin: 25px auto 0;
  width: 120px;
}
@media screen and (min-width: 768px) {
  .footerComponent__wrapper .footerSharing__tripadvisorDiv {
    display: inline-block;
    margin: 0 0 0 50px;
  }
}
.footerComponent__wrapper .footerSharing__tripadvisorSvg {
  height: 28px;
  width: 120px;
}
.brands-collection {
  color: #1e1852;
}
.brands-collection__elements--logos {
  fill: #1e1852;
}
.brands-collection__elements--urls:hover {
  background-color: #f4f4f5;
}
.brands-collection__wrapper>.brands-collection__logo>svg{
  background-color: #f4f4f5;
  fill: #1e1852!important;
  height: 70px;
  padding: 0 30px;
}
.footerComponent__wrapper .footerLegalLogos__logo--secure {
  fill: #1e1852;
}

.footerComponent__wrapper section.callCenter{
  -ms-flex-preferred-size:calc(25% - 32px);
  flex-basis:calc(25% - 32px);
  margin:0;
  padding:32px 0 32px 32px
}
@media screen and (max-width:767px){
  .footerComponent__wrapper section.callCenter{
      -ms-flex-preferred-size:100%;
      flex-basis:100%
  }
}
@media screen and (min-width:768px) and (max-width:1023px){
  .footerComponent__wrapper section.callCenter{
      -ms-flex-preferred-size:50%;
      flex-basis:50%
  }
}
@media screen and (min-width:1024px) and (max-width:1279px){
  .footerComponent__wrapper section.callCenter{
      -ms-flex-preferred-size:50%;
      flex-basis:50%;
      padding: 0;
  }
}
@media screen and (max-width:1023px){
  .footerComponent__wrapper section.callCenter{
      padding-left:0
  }
}
.footerComponent__wrapper section.callCenter .callCenter__title{
  display:none;
  -ms-flex-order:1;
  order:1;
  width:100%
}
.footerComponent__wrapper section.callCenter .callCenter__title--limitless{
  display:block
}
.footerComponent__wrapper section.callCenter .callCenter__ul{
  -ms-flex-order:3;
  display: flex;
  flex-direction: column;
  align-items: center;
  order:3
}
.footerComponent__wrapper section.callCenter .callCenter__ul:last-child{
  margin-top:12px
}
.footerComponent__wrapper section.callCenter .callCenter__li{
  margin-top:12px
}
.footerComponent__wrapper section.callCenter .callCenter__li:first-child{
  margin-top:0
}
.footerComponent__wrapper section.callCenter .callCenter__p{
  display:inline
}
.footerComponent__wrapper section.callCenter .callCenter__after-stars{
  font-family:"Roboto",Arial,sans-serif;
  font-size:12px;
  line-height:16px;
  font-weight:400;
  display:block;
  margin-top:10px
}
.footerComponent__wrapper section.callCenter .legal{
  display:none
}
.france .footerComponent__wrapper .callCenter{
  display:-ms-flexbox;
  display:flex;
  -ms-flex-direction:column;
  flex-direction:column;
  font-family:Arial;
  text-align:center
}
.france .footerComponent__wrapper .callCenter__li{
  width:fit-content
}
.france .footerComponent__wrapper .callCenter .dial{
  font-family:"Roboto",Arial,sans-serif;
  font-size:14px;
  line-height:20px;
  background:#fff;
  color:#a50f78;
  font-weight:700;
  padding:5px;
  position:relative
}
.france .footerComponent__wrapper .callCenter .dial::before{
  background:#fff;
  bottom:-5px;
  content:"";
  display:block;
  height:10px;
  left:10px;
  position:absolute;
  right:-5px;
  transform:rotate(45deg);
  width:10px
}
.france .footerComponent__wrapper .callCenter .dial a,.france .footerComponent__wrapper .callCenter .dial__stars{
  color:#a50f78
}
.france .footerComponent__wrapper .callCenter .legal{
  font-family:"Roboto",Arial,sans-serif;
  font-size:12px;
  line-height:16px;
  font-weight:500;
  letter-spacing:1px;
  background:#a50f78;
  color:#fff;
  display:block;
  margin:0;
  padding:10px 5px 5px
}
.france .footerComponent__wrapper .callCenter.free .dial{
  color:#91919b
}
.france .footerComponent__wrapper .callCenter.free .legal{
  background:#91919b
}
.china .footerComponent__wrapper .footerSharing__li{
  display:none
}
.china .footerComponent__wrapper .footerSharing__li--weibo{
  display:block
}
@media screen and (min-width:768px){
  .china .footerComponent__wrapper .footerSharing__li--weibo{
      display:inline-block
  }
}
[dir=rtl] .footerComponent__wrapper .callCenter .dial{
  max-width:200px
}

.brands-collection__allBrands {
  color: #050505;
  border: 1px solid #050033;
  background: transparent;
}
.brands-collection__allBrands:hover {
  color: white;
  background: #050033;
}
.brands-collection__hr--main {
  border: 1px solid #DADADD;
}
#tripadvisor path:nth-child(6), #tripadvisor path:nth-child(7) {
  fill: black;
}
.brands-collection__wrapper > .brands-collection__logo > svg {
  background-color: #f4f4f5;
  display: block;
  fill: #fff;
  height: 80px;
  margin: -30px auto 45px;
  padding: 0;
  position: relative;
  max-width: 250px;
  width: 200px;
}
.footerComponent__wrapper .footerLinks .linksList__ul.open{
  max-height:100%;
  overflow:visible;
}
.dial {
  font-family: "Roboto", Arial, sans-serif;
}
.brands-collection__elements--logos:hover {
  fill: #f4f4f5;
}
.brands-collection__elements--urls:hover {
  background-color: #1e1852;
}
